import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Snackbar, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const StyledInfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fill: theme.colors.partner.font.primary,
  height: theme.spacing(5),
  width: theme.spacing(5),
  alignSelf: 'center'
}));

const ServiceFeeAlert = ({ fee }: { fee: number }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Snackbar
      open={true}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{ width: '100%' }}
    >
      <Alert
        icon={<StyledInfoIcon />}
        color={undefined}
        sx={{
          backgroundColor: theme.colors.base.black,
          color: theme.colors.base.white,
          width: `calc(100% - ${theme.spacing(8)})`,
          fontSize: theme.spacing(4)
        }}
      >
        {t('restaurant.menu.serviceFeeAlert').replace('(FEE)', `${fee}%`)}
      </Alert>
    </Snackbar>
  );
};

export default ServiceFeeAlert;
