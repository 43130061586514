import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CircleDiv from '../../../../components/CircleDiv/CircleDiv';
import { default as Icon } from '../../../../components/AllergenIcon/AllergenIcon';
import { CartStatus } from '../../../Cart/types';
import { useCartV2 } from '../../../../hooks/useCartV2';
import { StoreInterface } from '../../../../types/stores';
import { ReactComponent as HandWaiveSVG } from '../../../../assets/images/logo-icons/waving-hand.svg';
import { usePickupTimes } from '../../../../hooks/usePickupTimes';
import CartButton from '../CartButton/CartButton';
interface CartTrackingDrawerProps {
  store: StoreInterface;
}

const ContentContainerStyled = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(6),
  borderTopLeftRadius: theme.spacing(2),
  borderTopRightRadius: theme.spacing(2),
  marginTop: theme.spacing(14),
  margin: 'auto',
  maxWidth: theme.breakpoints.values.lg
}));

const CartDrawerStyled = styled(Drawer)(() => ({
  '.MuiPaper-root': {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.05)' // transparent actually causes a color difference
  },
  zIndex: 3000
}));

const CartTrackingTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 700
}));

const CartTrackingMessageStyled = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(2),
  textAlign: 'center'
}));

const CartTrackingAddEmptyButtonStyled = styled(Button)(({ theme }) => ({
  borderColor: theme.colors.partner.primary,
  borderRadius: theme.spacing(2),
  color: theme.colors.partner.primary,
  fontSize: theme.spacing(4),
  fontWeight: 400,
  height: theme.spacing(12),
  marginBottom: theme.spacing(4),
  textTransform: 'capitalize',
  width: '100%'
}));

const CartButtonStyled = styled(CartButton)(() => ({
  bottom: 0
}));

const WELCOME_BACK_SESSION_KEY = 'welcomeBackAlert';

// This shows the Welcome Back alert to the user
//
// to criteria to show the Welcome Back alert is
// the cart status must be ACTIVE, there must be items in the cart and sessionStore doesnt have 'welcomeBackAlert' item
// a cart status is considered ACTIVE when the current user time is within 30minutes of lastModified field in the cart
//
// when the page/tab is closed
//  we remove 'welcomeBackAlert' from sessionStorage
//
// when the page is reloaded or opened again
// if cart status is active and there are items in cart then
// we show 'welcomeBackAlert' only if its not in sessionStorage
// this enables us to prevent showing 'welcomeBackAlert' every time

const CartTrackingDrawer = ({ store }: CartTrackingDrawerProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { setLastModified, getStatus, items } = useCartV2();
  const { data: times, isFetched } = usePickupTimes(store.id);
  let trackingMessage = '';

  const [cartStatus, setCartStatus] = useState<
    CartStatus.EXPIRED | CartStatus.EXPIRING | CartStatus.ACTIVE
  >(() => getStatus());

  const [openTrackCartDrawer, setOpenTrackCartDrawer] = useState<boolean>(
    () => {
      if (cartStatus === CartStatus.ACTIVE && items.length > 0) {
        if (sessionStorage.getItem(WELCOME_BACK_SESSION_KEY) === 'shown') {
          return false;
        }

        return true;
      }

      return false;
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (cartStatus !== CartStatus.ACTIVE) {
        sessionStorage.removeItem(WELCOME_BACK_SESSION_KEY);
      }

      setCartStatus(cartStatus);
    }, 60000);

    return () => {
      if (!interval) {
        return;
      }

      clearInterval(interval);
    };
  }, [getStatus]);

  useEffect(() => {
    const onPageClose = () => {
      if (document.visibilityState === 'hidden') {
        sessionStorage.removeItem(WELCOME_BACK_SESSION_KEY);
      }
    };

    window.addEventListener('visibilitychange', onPageClose);

    return () => window.removeEventListener('visibilitychange', onPageClose);
  }, []);

  const handleAdd = () => {
    setLastModified();
    sessionStorage.setItem(WELCOME_BACK_SESSION_KEY, 'shown');
    setOpenTrackCartDrawer(false);
  };

  const active = cartStatus === CartStatus.ACTIVE;

  if (active && items.length > 0) {
    trackingMessage = t('restaurant.cart.savedMsg');
  }

  if (isFetched && times && times.length <= 0) {
    return null;
  }

  if (active && sessionStorage.getItem(WELCOME_BACK_SESSION_KEY) === 'shown') {
    return null;
  }

  return (
    <CartDrawerStyled
      open={openTrackCartDrawer}
      transitionDuration={1300}
      anchor="bottom"
      onClose={() => {
        handleAdd();
      }}
      data-testid={'cart-drawer'}
    >
      <ContentContainerStyled>
        <CircleDiv style={{ backgroundColor: theme.colors.partner.primary }}>
          <Icon
            AllergenSVG={HandWaiveSVG}
            height="40"
            width="26"
            ariaHidden={true}
          />
        </CircleDiv>
        <CartTrackingTitleStyled
          data-testid={`cart-tracking-title-${t(
            'restaurant.cart.welcomeBack'
          )}`}
        >
          {t('restaurant.cart.welcomeBack')}
        </CartTrackingTitleStyled>
        <CartTrackingMessageStyled
          data-testid={`tracking-message-${trackingMessage}`}
        >
          {trackingMessage}
        </CartTrackingMessageStyled>
        {items.length > 0 && (
          <CartTrackingAddEmptyButtonStyled
            variant="outlined"
            onClick={handleAdd}
            data-testid={`cart-tracking-addMoreItems`}
          >
            {t('restaurant.cart.addMoreItems')}
          </CartTrackingAddEmptyButtonStyled>
        )}
        {items.length > 0 && <CartButtonStyled store={store} />}
      </ContentContainerStyled>
    </CartDrawerStyled>
  );
};

export default CartTrackingDrawer;
