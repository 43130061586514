import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import { default as LogoIcon } from '../../../../components/AllergenIcon/AllergenIcon';
import { ReactComponent as CreditCardSVG } from '../../../../assets/images/logo-icons/credit-card.svg';
import { ReactComponent as ApplePaySVG } from '../../../../assets/images/logo-icons/apple-pay.svg';
import { ReactComponent as GooglePaySVG } from '../../../../assets/images/logo-icons/google-pay.svg';
import Typography from '@mui/material/Typography';
import CreditCard from '../CreditCard';
import GooglePay from '../GooglePay';
import ApplePay from '../ApplePay';
import { GatewayResponse } from '../../types/GatewayResponse';
import loadScript from '../../../../utils/Payments/FreedomPay/loadScript';
import { GatewayError } from '../../GatewayError';
import fetchCountries, {
  Country
} from '../../../../utils/Payments/FreedomPay/fetchCountries';

interface SelectPaymentProps {
  siteId: string;
  storeId: string;
  menuId: number;
  orderId: string;
  transactionTotal: number;
  orderTimeStamp: string;
  sessionTimeout: () => void;
  onError: (error: GatewayError) => void;
  onSuccess: (formResponse: GatewayResponse) => void;
}

const Title = styled('h6')(({ theme }) => ({
  fontSize: theme.spacing(5),
  fontWeight: 700,
  marginTop: '3px',
  marginBottom: '16px'
}));

const SelectionContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.colors.gray[300]}`,
  borderRadius: '4px',
  marginBottom: theme.spacing(4),
  fontSize: theme.spacing(4),
  padding: theme.spacing(5),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  cursor: 'pointer'
}));

const SelectionHeader = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start'
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(4)
}));

const PaymentText = styled(Typography)(({ theme }) => ({
  variant: 'body1',
  marginLeft: theme.spacing(4)
}));

const SelectPaymentMethod = ({
  siteId,
  storeId,
  menuId,
  orderId,
  transactionTotal,
  orderTimeStamp,
  sessionTimeout,
  onError,
  onSuccess
}: SelectPaymentProps) => {
  const { t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [isAppleAvailable, setIsAppleAvailable] = useState<boolean>(false);
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    loadScript().catch((err) => {
      console.error(err);
    });
  }, [onError]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(event.target.value);
  };

  const handleContainerClick = (value: string) => {
    setPaymentMethod(value);
  };

  useEffect(() => {
    if (!window.ApplePaySession) {
      setIsAppleAvailable(false);
      return;
    }
    setIsAppleAvailable(true);
  }, []);

  useEffect(() => {
    const loadCountries = async () => {
      try {
        const data = await fetchCountries();
        setCountries(data);
      } catch (err) {
        console.error('Failed to fetch countries', err);
      }
    };
    loadCountries();
  }, []);

  return (
    <>
      <Title>{t('restaurant.checkout.selectPaymentMethod')}</Title>
      <FormControl component="fieldset" style={{ width: '100%' }}>
        {isAppleAvailable && (
          <SelectionContainer onClick={() => handleContainerClick('applePay')}>
            <SelectionHeader>
              <StyledRadio
                data-testid="apple-radio"
                checked={paymentMethod === 'applePay'}
                onChange={handleChange}
                value="applePay"
                name="paymentMethod"
              />
              <LogoIcon
                height="24"
                width="40"
                AllergenSVG={ApplePaySVG}
                title="knife icon"
                dropShadow={false}
                ariaHidden={true}
              />
              <PaymentText>{t('restaurant.checkout.applePay')}</PaymentText>
            </SelectionHeader>
            {paymentMethod === 'applePay' && (
              <ApplePay
                orderId={orderId}
                siteId={siteId}
                storeId={storeId}
                menuId={menuId}
                transactionTotal={transactionTotal}
                orderTimeStamp={orderTimeStamp}
                onError={onError}
                onSuccess={onSuccess}
              />
            )}
          </SelectionContainer>
        )}
        <SelectionContainer onClick={() => handleContainerClick('googlePay')}>
          <SelectionHeader>
            <StyledRadio
              data-testid="google-radio"
              checked={paymentMethod === 'googlePay'}
              onChange={handleChange}
              value="googlePay"
              name="paymentMethod"
            />
            <LogoIcon
              height="24"
              width="40"
              AllergenSVG={GooglePaySVG}
              title="knife icon"
              dropShadow={false}
              ariaHidden={true}
            />
            <PaymentText>{t('restaurant.checkout.googlePay')}</PaymentText>
          </SelectionHeader>
          {paymentMethod === 'googlePay' && (
            <GooglePay
              orderId={orderId}
              siteId={siteId}
              storeId={storeId}
              menuId={menuId}
              transactionTotal={transactionTotal}
              orderTimeStamp={orderTimeStamp}
              countries={countries}
              onError={onError}
              onSuccess={onSuccess}
            />
          )}
        </SelectionContainer>
        <SelectionContainer onClick={() => handleContainerClick('creditCard')}>
          <SelectionHeader>
            <StyledRadio
              data-testid="cc-radio"
              checked={paymentMethod === 'creditCard'}
              onChange={handleChange}
              value="creditCard"
              name="paymentMethod"
            />
            <LogoIcon
              height="24"
              width="40"
              AllergenSVG={CreditCardSVG}
              title="knife icon"
              dropShadow={false}
              ariaHidden={true}
            />
            <PaymentText>{t('restaurant.checkout.creditCard')}</PaymentText>
          </SelectionHeader>
          {paymentMethod === 'creditCard' && (
            <CreditCard
              orderId={orderId}
              siteId={siteId}
              storeId={storeId}
              menuId={menuId}
              transactionTotal={transactionTotal}
              orderTimeStamp={orderTimeStamp}
              countries={countries}
              sessionTimeout={sessionTimeout}
              onError={onError}
              onSuccess={onSuccess}
            />
          )}
        </SelectionContainer>
      </FormControl>
    </>
  );
};

export default SelectPaymentMethod;
