import { Country } from './fetchCountries';

const getCountryCallingCode = (
  alpha2: string,
  countries: Country[]
): string | undefined => {
  const countryMatch = countries.find((country) => country.alpha2 === alpha2);
  return countryMatch ? countryMatch.callingCode : undefined;
};

export default getCountryCallingCode;
