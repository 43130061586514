import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const DialogBox = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    paddingTop: theme.spacing(4.5),
    paddingBottom: theme.spacing(4.5),
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5),
    textAlign: 'center'
  }
}));

const StyledTitle = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(2)
}));

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.colors.partner.primary,
  marginRight: theme.spacing(4.5),
  textAlign: 'center',
  display: 'block',
  margin: 'auto'
}));

const LoadingText = styled('p')(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 400,
  color: theme.colors.base.black
}));

const ProcessingOrderDialog = () => {
  const { t } = useTranslation();

  return (
    <DialogBox
      open={true}
      data-testid="loading-dialog-box"
      style={{ zIndex: 2001 }}
    >
      <StyledTitle data-testid="loading-dialog-box-title">
        {t('dialog.processingOrder.title')}
      </StyledTitle>
      <LoadingSpinner size={20} data-testid="loading-spinner" />
      <LoadingText data-testid="loading-text">
        {t('dialog.processingOrder.text')}
      </LoadingText>
    </DialogBox>
  );
};

export default ProcessingOrderDialog;
